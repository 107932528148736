import React from 'react';

export default function Footer() {
    // Get the current year
    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className="footer">
                <p>
                    Copyright © {currentYear} All Rights Reserved By <span className="text-primary">PowerITBD</span> Product
                </p>
            </div>
        </>
    );
}
